(() => {
  // handling the menu open and close in the mobile view
  const handleMobileMenu = () => {
    const menuOpenButton = document.querySelector(
      '#btx-header-toggle'
    ) as HTMLButtonElement;

    window.Bus.on('emu-button:click', ({ id }) => {
      // when close button is clicked, click the open button to toggle the dropdown
      if (id === 'btx-header-close') {
        menuOpenButton?.click();
      }
    });
  };

  // handling lang toggling
  const handleLangToggle = () => {
    let $langEls: NodeListOf<HTMLAnchorElement>;
    // Create a new URL object with the current window location
    const currentUrl = new URL(window.location.href);

    // Remove the query parameters and hash fragment
    currentUrl.search = ''; // This removes the query parameters
    currentUrl.hash = ''; // This removes the hash fragment

    // Get the clean URL
    const href = currentUrl.toString().replace(/\/$/, '');

    const handleAnchorClick = (e, $el) => {
      const elHref = $el.getAttribute('href') as string;
      let finalURL = '';
      e.preventDefault();

      // if clicked element has fr in the URL
      if (elHref.includes('/fr')) {
        // if user is also on an fr page, just refresh the page
        if (href.includes('/fr')) {
          finalURL = href;
        } else {
          finalURL = href.replace(
            `${window.location.origin}`,
            `${window.location.origin}/fr`
          );
        }
      } else {
        // if user is also on an en page, just refresh the page
        if (href.includes('/en')) {
          finalURL = href;
        } else {
          finalURL = href.replace(
            `${window.location.origin}/fr`,
            `${window.location.origin}`
          );
        }
      }

      try {
        // replacing the double slash
        const url = new URL(finalURL);
        url.pathname = url.pathname.replaceAll('//', '/');
        finalURL = url.href;

        window.location.href = finalURL;
      } catch (e) {
        console.warn(e);
      }
    };

    const initVariables = () => {
      $langEls = document.querySelectorAll(
        '.btx-header__lang-switch-container a'
      );
    };

    const appendEvents = () => {
      $langEls.forEach($el => {
        $el.addEventListener('click', e => {
          handleAnchorClick(e, $el);
        });
      });
    };

    initVariables();
    appendEvents();
  };

  //Handling anchors containing onetrust
  const handleOneTrust = () => {
    // Select all anchor elements on the page with an href containing #onetrust.
    const $anchorElements = document.querySelectorAll(
      'a[href="#onetrust"]'
    ) as NodeListOf<HTMLAnchorElement>;

    // Skip the first element and add the event listener to the rest
    $anchorElements.forEach(($el, index) => {
      if (index !== 0) {
        $el.addEventListener('click', e => {
          e.preventDefault(); // Prevent the default action
          $anchorElements[0].click(); // Trigger a click on the first anchor element
        });
      }
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
      handleMobileMenu();
      handleLangToggle();
      handleOneTrust();
    });
  } else {
    handleMobileMenu();
    handleLangToggle();
    handleOneTrust();
  }
})();
